import React from "react"
import './timeline.css'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { SiHandshake } from 'react-icons/si'
import { TbHeartHandshake } from 'react-icons/tb'
import { BsFillCalendarDateFill, BsFillSuitHeartFill } from 'react-icons/bs'
import { MdPark, MdOutlineCelebration, MdTravelExplore} from 'react-icons/md'
import { GiHeartKey, GiGlassCelebration, GiCampingTent } from 'react-icons/gi'
import { BiDonateHeart, BiCalendarHeart } from 'react-icons/bi'
import { AiOutlineQuestion } from 'react-icons/ai'
import { FaSnowboarding } from 'react-icons/fa'
import oneYear from '../../assets/portrait/1year.png'
import twoYear from '../../assets/portrait/2year.png'
import twoTwo from '../../assets/portrait/22.JPG'
import bali from '../../assets/portrait/bali.JPG'
import beginning from '../../assets/portrait/beginning.JPG'
import camping from '../../assets/portrait/camping.png'
import falls from '../../assets/portrait/falls.JPG'
import komeyui from '../../assets/portrait/komeyui_silly.JPG'
import picnic from '../../assets/portrait/picnic.png'
import tassy from '../../assets/portrait/tassy.JPG'

const icon_color = 'rgb(255, 255, 255)'

const Timeline = () => {
    return (
        <section id='timeline'>
        <h2>Our Timeline</h2>
        <VerticalTimeline>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                // contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                // contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                date="2019 - July?"
                iconStyle={{ background: icon_color, color: '#fff' }}
                icon={<TbHeartHandshake className="icon"/>}
                icon_color={'black'}
            >
                <h3 className="vertical-timeline-element-title">Our First Meeting</h3>
                <h4 className="vertical-timeline-element-subtitle">The real one</h4>
                <p>
                I know you feel in love with me here at mapo. You even hit my up on snapchat after... (this is my code so you can't change these facts)
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="2019 - August?"
                iconStyle={{ background: icon_color, color: '#fff' }}
                icon={<SiHandshake className="icon"/>}
            >
                <h3 className="vertical-timeline-element-title">Our First Date</h3>
                <h4 className="vertical-timeline-element-subtitle">You in your stinky stussy jacket</h4>
                <p>
                I still remember rolling my selves up to show off my arms xD. I loved this date and I still remember how I felt when you held my hand.
                Thank you for listening to me that day on the bus home. It made me fall HARD for you hehe.
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="2019 - September"
                iconStyle={{ background: icon_color, color: '#fff' }}
                icon={<BsFillCalendarDateFill className="icon" />}
            >
                <h3 className="vertical-timeline-element-title">The beginning</h3>
                <h4 className="vertical-timeline-element-subtitle">Of our stinkiess</h4>
                <p>
                I really wish that we had any light on that day. I'm still sad I didn't get to see your reaction when I asked. Also throwback to me 
                ninjaing into your house HAHAHAHA crazy times
                </p>
                <img className="timeline__image" src={beginning}></img>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="2019 - November"
                iconStyle={{ background: icon_color, color: '#fff' }}
                icon={<MdPark className="icon" />}
            >
                <h3 className="vertical-timeline-element-title">Our first Picnic</h3>
                <h4 className="vertical-timeline-element-subtitle">sho much fun</h4>
                <p>
                This was and still is one of my favourite things to do with you. I loved how peaceful this was I remember telling you how much I enjoyed it.
                I just felt so surprised that something free could be so fun.
                </p>
                <img className="timeline__image" src={picnic}></img>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="2020 - January"
                iconStyle={{ background: icon_color, color: '#fff' }}
                icon={<GiHeartKey className="icon" />}
            >
                <h3 className="vertical-timeline-element-title">Our First I Love You</h3>
                <h4 className="vertical-timeline-element-subtitle">I got to see this one</h4>
                <p>
                I've told you this so many times now but I'll say it once more. On that flight to Japan I was slobbering like a stinky bugga. Crying to the movie about a guy and his dog. I knew then and there I loved you 
                and wanted to tell you immediately. I was so excited to tell you and I'm so grateful that I'm able to tell you everyday. I love you silly bugga.
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="2020 - February"
                iconStyle={{ background: icon_color, color: '#fff' }}
                icon={<BiDonateHeart className="icon" />}
            >
                <h3 className="vertical-timeline-element-title">Bali</h3>
                <h4 className="vertical-timeline-element-subtitle">Secret stuff happened in your living room</h4>
                <p>
                I just wanted to shout out Bali. This was the first time you had really taken care of me when I got hives all over. It showed me how loving and caring you were, and you've continued to show me this love and care to this day. U SMELL I LOVE YOU
                </p>
                <img className="timeline__image" src={bali}></img>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="2020 - June"
                iconStyle={{ background: icon_color, color: '#fff' }}
                icon={<MdOutlineCelebration className="icon" />}
            >
                <h3 className="vertical-timeline-element-title">Your 22nd Birthday</h3>
                <h4 className="vertical-timeline-element-subtitle">Surprise!</h4>
                <p>
                This was the hardest I've ever worked for a birthday in terms of decorating, and your reaction was worth it all. I loved this surprise and I hope that I can surprise you more like this in the future. (No more surprise group events I know, I know)
                </p>
                <img className="timeline__image" src={twoTwo}></img>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="2020 - September"
                iconStyle={{ background: icon_color, color: '#fff' }}
                icon={<GiGlassCelebration className="icon" />}
            >
                <h3 className="vertical-timeline-element-title">1 Year Anniversary</h3>
                <h4 className="vertical-timeline-element-subtitle">Wooh Covid!!</h4>
                <p>
                Our first Anniversary locked up inside. I don't think your parents even knew about us then xD
                </p>
                <img className="timeline__image" src={oneYear}></img>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="2021 - January"
                iconStyle={{ background: icon_color, color: '#fff' }}
                icon={<GiCampingTent className="icon" />}
            >
                <h3 className="vertical-timeline-element-title">Our First Camping Trip</h3>
                <h4 className="vertical-timeline-element-subtitle">Forever a core memory</h4>
                <p>
                I loved this trip. Everything about it was perfect and the food was so yummy. I can't wait for more camping trips like this. I also can't wait to buy so much cool stuff in Japan hehe
                </p>
                <img className="timeline__image" src={camping}></img>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="2021 - September"
                iconStyle={{ background: icon_color, color: '#fff' }}
                icon={<BiCalendarHeart className="icon" />}
            >
                <h3 className="vertical-timeline-element-title">2 Year Anniversary</h3>
                <h4 className="vertical-timeline-element-subtitle">Wow another lockdown Anni!</h4>
                <p>
                Covid stole our youth >:^(. It's okay because we still have so much time to grow and explore the world! But GRRR covid.
                </p>
                <img className="timeline__image" src={twoYear}></img>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="2022 - April"
                iconStyle={{ background: icon_color, color: '#fff' }}
                icon={<MdTravelExplore className="icon" />}
            >
                <h3 className="vertical-timeline-element-title">Tassy</h3>
                <h4 className="vertical-timeline-element-subtitle">Our first trip together</h4>
                <p>
                I loved everything about this trip. EVEN the part where Jetstar lost our luggage. Thank you for planning it, without you planning I don't think the trip would be nearly as amazing. 
                The tranquility of the trip and nature that we were able to experience was so unbeatable. I can't wait for more trips together.
                </p>
                <img className="timeline__image" src={tassy}></img>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="2022 - July"
                iconStyle={{ background: icon_color, color: '#fff' }}
                icon={<FaSnowboarding className="icon" />}
            >
                <h3 className="vertical-timeline-element-title">Falls Creek</h3>
                <h4 className="vertical-timeline-element-subtitle">Our first snow trip</h4>
                <p>
                This bloody ACL needs to fix. But once it does, we will RETURN! I know we had a hard time during that trip but overall I really loved going with you and I can't wait for us to shred the mountains together with our STINKS
                </p>
                <img className="timeline__image" src={falls}></img>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="2022 - September"
                iconStyle={{ background: icon_color, color: '#fff' }}
                icon={<BsFillSuitHeartFill className="icon" />}
            >
                <h3 className="vertical-timeline-element-title">3 Year Anniversary</h3>
                <h4 className="vertical-timeline-element-subtitle">The whole reason I made this website</h4>
                <p>
                Although this was months ago now, I love you so much. I can't wait for more yummy food that we eat and adventures that we go on. You're my partner in stink, and I'm so excited to see what we do in the future and everything that we'll 
                achieve together.
                </p>
                <img className="timeline__image" src={komeyui}></img>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                iconStyle={{ background: icon_color, color: '#fff' }}
                icon={<AiOutlineQuestion className="icon" />}
            />
            </VerticalTimeline>
        </section>
    )
}

export default Timeline