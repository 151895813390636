import React from 'react';
import Header from './components/header/Header';
import Navbar from './components/navbar/Navbar';
import Carousel from './components/carousel/Carousel';
import Message from './components/message/Message';
import Timeline from './components/timeline/Timeline';
import Inquiry from './components/inquiry/Inquiry';

function App() {
  return (
    <>
      <Header/>
      <Navbar/>
      <Carousel/>
      <Message/>
      <Timeline/>
      <Inquiry/>
    </>
  );
}
  
export default App;