import './carousel.css'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import image1 from '../../assets/landscape/wedding.JPG' 
import image2 from '../../assets/landscape/hepburn.png' 
import image3 from '../../assets/landscape/campingSmile.png' 
import image4 from '../../assets/landscape/apolloBay.png' 
import image5 from '../../assets/landscape/picnic.PNG' 
import image6 from '../../assets/landscape/entrecote.JPG' 
import image7 from '../../assets/landscape/7month.png' 
import image8 from '../../assets/landscape/campingSilly.png' 



const data = [
    {
        id:1,
        image: image1,
        name: 'image1'
    },
    {
        id:2,
        image: image2,
        name: 'image2'
    },
    {
        id:3,
        image: image3,
        name: 'image3'
    },
    {
        id:4,
        image: image4,
        name: 'image4'
    },
    {
        id:5,
        image: image5,
        name: 'image5'
    },
    {
        id:6,
        image: image6,
        name: 'image6'
    },
    {
        id:7,
        image: image7,
        name: 'image7'
    },
    {
        id:8,
        image: image8,
        name: 'image8'
    },
]

const Carousel = () => {
    return (
        <section id='carousel'>
        <Swiper
            pagination={{
                clickable: true,
                dynamicBullets: true
              }}
            loop={ true }
            autoplay={{ 
                delay: 6000,
                disableOnInteraction: false
            }}           
            // navigation={true}
            modules={ [Pagination, Autoplay] }
            spaceBetween={30}
            slidesPerView={1}
            className="container carousel__container">
        {
        data.map(({id, image, name}) => {
            return (
            <SwiperSlide className='carousel' key={id}>
                <div className='swiper-slide'>
                <img src={image} alt={name}/>
                </div>
            </SwiperSlide>
                    )
                    })
                }
        </Swiper>
        </section>
    )
}

export default Carousel