import './inquiry.css'
import { MdOutlineMailOutline } from 'react-icons/md'
import React, { useRef } from 'react';
import emailjs, { send } from '@emailjs/browser';


const Inquiry = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_m6pehwt', 'template_x3zcplo', form.current, 'ahHG9u-cbDgCwkBHP')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    e.target.reset();
  };

  return (
    <section id='inquiry'>
      <h5>Get In Stinky</h5>
      <h2>Stink Me</h2>

      <div className='container inquiry__container'>
        <div className='inquiry__options'>
          <article className='inquiry__option'>
            <MdOutlineMailOutline className='inquiry__option-icon'/>
            <h4>Email</h4>
            <h5>stinky1@sillybuggas.xyz</h5>
            <a href='mailto: jackson.zhang97@gmail.com'>Stinky me a Message</a>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input type='text' name='name' placeholder="Stinky Name" required />
          <textarea name='message' rows='7' placeholder='Stinky Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Inquiry