import React from 'react'
import './navbar.css'
import { useState } from 'react'
import { BiPhotoAlbum } from 'react-icons/bi'
import { SiTinyletter } from 'react-icons/si'
import { RiTimeLine } from 'react-icons/ri'
import { AiOutlineMail } from 'react-icons/ai'

const Navbar = () => {
    const [activeNav, setActiveNav] = useState('#header')
    return (
        <nav>
            <a href="#header" onClick={() => setActiveNav("#header")} className={activeNav === '#header' ? 'active' : ''}><BiPhotoAlbum/></a>
            <a href="#message" onClick={() => setActiveNav("#message")} className={activeNav === '#message' ? 'active' : ''}><SiTinyletter/></a>
            <a href="#timeline" onClick={() => setActiveNav("#timeline")} className={activeNav === '#timeline' ? 'active' : ''}><RiTimeLine/></a>
            <a href="#inquiry" onClick={() => setActiveNav("#inquiry")} className={activeNav === '#inquiry' ? 'active' : ''}><AiOutlineMail/></a>
        </nav>
    )
}

export default Navbar