import React from "react"
import './header.css'

const Header = () => {
    return (
      <header id = 'header'>
          <div className='container header__container'>
              <h4>Welcome</h4>
              <h1 className="big-text">Stinky Binky</h1>
              <h4 className='text-light'>To our 3 year anniversay website</h4>
          </div>
      </header>
    )
  }

export default Header