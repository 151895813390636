import React from "react"
import './message.css'

const Message = () => {
    return (
        <section id='message'>
            <div className="message__div">
                <h2> Dear Stinky, </h2>
                Thank you for being so patient with me making this website. I don't know if you had any idea what I was doing but this was a big learning
                experience for me and I feel more confident for when I build you portfolio hehe. Now onto why I made this website. 
                I thought this could be something to keep forever. Something that we could design and develop together.
            </div>
            <div className="note__div">
                To my silliest smelly bugga I love you the most. And so that you can remember why I love you and revisit this website I will list them below.
                <p className="p__style">1. Caring: You are always looking out for me and making sure that I am comfortable. With you I have learnt a new level of comfort
                    and developed an ability to understand and communicate when I'm not comfortable. This is one of the many things you've helped me develop.
                </p>
                <p className="p__style"> 2. Loving: The love you give me always makes me feel so warm. Thats why I'm always feeling hot because you love me so much. As I always
                    say you're my most favourite stinky in the world. You make me feel loved and I hope you feel the same too.
                </p>
                <p className="p__style">
                    3. Smarty Pants: You are one of the smartest people I know and so hard working as well. I know we don't always see eye to eye on things,
                    but you're always showing me new perspectives and expanding my mind. You help me understand things I wouldn't have thought about previously,
                    and you're always showing me cool new things.
                </p>
                <p className="p__style">
                    4. Stinkiness: We always say that we're different people but I know that deep down in our hearts we are so stinky. I love how stinky we are together and 
                    I never want it to change. You make me feel like I can be the silliest person ever and you won't judge me (most of the time) and I love that 
                    we embrace this together.
                </p>

                There is so much more I could write but I want to keep it short. Just remember I love you the most and you are my most favourite stinky ever.
            </div>
        </section>
        
    )
}

export default Message